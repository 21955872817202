@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
/* font-family: 'Poppins', sans-serif; */

body {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300 !important;
    color: #fff;
    background-color: #151619;
}

body a,body a:hover {
    color: inherit;
    text-decoration: none;
    transition: all .4s ease;
    outline: none !important;
}

body p a:hover {
    color: #f0b961
}

body p {
    font-size: .875em;
}


.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
    font-weight: 700;
}


.text-main {
    color: #f0b961 !important;
}

.link:hover {
    color: #f0b961
}

.fs-12 {
    font-size: .75em !important;
}

.fs-14 {
    font-size: .875em !important;
}

.fs-18 {
    font-size: 1.125em !important;
}

.btn.btn1 {
    background: #f0b961;
    color: #000;
    border: 1px solid #f0b961;
    border-radius: 10px;
    padding: 12px 20px;
    min-width: 140px;
    transition: all .4s ease;
    box-shadow: none !important;
    outline: none;
    font-size: .875rem;
}

.btn.btn1:hover {
    background: #f0b961;
    border-color: #f0b961;
    color: #000;
    opacity: .85;
}

.btn.btn-white{
    background: #fff;
    border-color: #fff;
    color: #000;
}
.btn.btn-white:hover{
    background: #f0b961;
    border-color: #f0b961;
    color: #000;
}

.padding {
    padding: 20px 8px;
}

.ellipsis-2  {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}



.logo1 {
    width: 90px;
}


.header-top {
    background: transparent;
    padding: 10px 8px;
    color: #fff;
    /* border-bottom: 1px solid rgb(255 255 255 / 20%); */
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header-top button.btn {
    background: transparent;
    border: 0;
    color: #fff;
    padding: 0;
    font-size: 1.3em;
    box-shadow: none !important;
    outline: none !important;
}

.header-top button.btn.links1 {
    font-size: 1rem !important;
    border: 1px solid rgb(255 255 255 / 40%) !important;
    width: 35px;
    height: 35px;
    line-height: 15px;
    border-radius: 50%;
    padding: 0 !important;
    text-align: center;
}

.header-top button.btn.links1:hover {
    background-color: #f0b961 !important;
    border-color: #f0b961 !important;
}


.offcanvas.offcanvas1 {
    width: 280px;
}
.offcanvas.offcanvas1 {
    background: #000;
    box-shadow: 0 0 10px rgb(0 0 0/8%);
    border: 0;
}
.offcanvas.offcanvas1 .offcanvas-header {
    padding: 15px 20px;
    /* border-bottom: 1px solid rgb(255 255 255 / 20%); */
    height: 64px;
}
.offcanvas.offcanvas1 .offcanvas-body {
    padding: 20px 9px;
}
.offcanvas.offcanvas1 .btn-close {
    box-shadow: none;
    outline: none;
}

.navbar-nav1 .nav-link {
    padding: 12px 15px;
    color: #fff;
    border-radius: 10px;
    background: transparent;
    margin-bottom: 8px;
    font-size: .875rem;
}

.navbar-nav1 .nav-link svg {
    color: #fff;
    margin-right: 8px;
    font-size: 16px;
    margin-top: -3px;
}

.navbar-nav1 .nav-link.active,
.navbar-nav1 .nav-link:hover {
    color: #000;
    background-color: #f0b961;
}
.navbar-nav1 .nav-link.active svg,
.navbar-nav1 .nav-link:hover svg {
        color: #000;
}


.main-content {
    position: relative;
    min-height: 100vh;
    padding-bottom: 62px;
}


.dropdown.dropdown1 button.btn {
    background-color: transparent !important;
    border: 0;
    box-shadow: none !important;
    outline: none !important;
    padding: 0;
}

.dropdown.dropdown1 button.btn::after {
    border: 0;
    width: 8px;
    height: 8px;
    border-left: 2px solid rgb(255 255 255);
    border-bottom: 2px solid rgb(255 255 255);
    transform: rotate(-45deg);
}

.dropdown.dropdown1 button.btn .user1 {
    width: 35px;
    min-width: 35px;
    height: 35px;
    border-radius: 100px;
    margin-right: 2px;
}

.dropdown.dropdown1 .dropdown-menu {
    border-color: rgb(0 0 0 / 5%);
    box-shadow: 0 4px 5px rgb(0 0 0 / 6%);
    background: #212229;
    border-radius: 10px;
}

.dropdown.dropdown1 .dropdown-menu .dropdown-item {
    background: transparent !important;
    font-size: .875rem;
    color: #fff;
    padding: 6px 20px;
}

.dropdown.dropdown1 .dropdown-menu .dropdown-item:hover {
    color: #f0b961;
}


.navbar-nav1 .dropdown-menu {
    position: static !important;
    transform: translate3d(0px, 0, 0) !important;
    background: transparent;
    padding: 0;
}

.navbar-nav1 .dropdown-toggle {color: #fff !important;}

.navbar-nav1 .dropdown-toggle::after {
    position: absolute;
    left: auto;
    right: 12px;
    top: 19px;
    border: 1px solid #fff;
    border-top: 0;
    border-right: 0;
    background: transparent;
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
}

.navbar-nav1 .dropdown-toggle.show {
    background: #221d3e;
    color: #fff;
}

.navbar-nav1 .dropdown-menu .dropdown-item {
    padding: 8px 15px;
    color: #fff;
    border-radius: 4px;
    background: transparent;
    font-size: .875rem;
    font-weight: 300;
}

.navbar-nav1 .dropdown-menu .dropdown-item.active,
.navbar-nav1 .dropdown-menu .dropdown-item:hover {
    color: #f0b961;
}
.navbar-nav1 .dropdown-menu .dropdown-item .sports-arrow {
    font-size: 10px;
    margin-right: 6px;
}



/* form1 */
.form.form1 .form-label {
    color: #000;
    font-size: .875em;
    font-weight: 400;
}
.form.form1 .form-select, 
.form.form1 .form-control {
    border-radius: 10px;
    box-shadow: none;
    font-size: 1em;
    height: 50px;
    outline: none;
    background-color: #212229;
    border-width: 2px;
    border-color: rgb(255 255 255 / 12%);
    color: #fff;
    padding: 5px 20px;
    font-size: .875rem;
    font-weight: 300;
}

.form.form1 .form-select:focus,
.form.form1 .form-control:focus{
    border-color: #f0b961;
}

.form.form1 .form-select option {
    color: #fff;
}
.form.form1 .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    padding-right: 32px;
}


.form.form1 .form-control::placeholder {
    color: rgb(255 255 255 / 75%)
}

.form.form1 input[type=file]::-webkit-file-upload-button,
.form.form1 input[type=file]::file-selector-button {
    height: 50px
}

.form.form1 .ck-editor__editable_inline {
    min-height: 200px;
}

.form.form1 .icon-box {
    position: relative;
}
.form.form1 .icon-box .icon {
    background: transparent;
    color: #fff;
    border-radius: 50%;
    font-size: 1.1rem;
    width: 42px;
    height: 42px;
    line-height: 40px;
    position: absolute;
    top: 4px;
    right: 4px;
    text-align: center;
}
.form.form1 .icon-box .form-select,
.form.form1 .icon-box .form-control {
    padding-right: 56px;
}

.form.form1 .form-check-input {
    box-shadow: none !important;
    outline: none !important;
    width: 35px;
    height: 19px;
    cursor: pointer;
}

.form.form1 .form-check-input:focus {
    border-color: #f0b961;
}
.form.form1 .form-check-input:checked {
  border-color: #f0b961 !important;
  background-color: #f0b961 !important;
}
.form.form1 .bg1 .form-select,
.form.form1 .bg1 .form-control {
    height: 34px;
    background-color: #151619;
}
.form.form1 .icon {
    position: relative;
}

.form.form1 .icon .txt1 {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.form.form1 .icon .form-control {
    padding-right: 35px;
}

.form.form1 input[type="date"] {
    color-scheme: dark;
}

.form.form1 input[type=file]::file-selector-button {
    border: 0;
    padding: 5px 15px;
    border-radius: .2em;
    background-color: rgb(255 255 255 / 12%) !important;
    color: #fff;
    transition: 1s;
}

.form.form1 input.pincode-input-text {
    border-radius: 8px;
    margin: 0 6px !important;
    color: #fff;
}

.ck.ck-powered-by {
    display: none ;
}

.error-text {
    font-size: .875em;
    color: red;
    margin-left: 4px;
    margin-top: 3px;
    margin-bottom: 0;
}




/* login-section */
.login-section {
    background: #f0b961;
    background: radial-gradient(#f0b961, #000);
}

.login-section .logo1 {
    width: 140px
}

.login-box {
    background: #151619;
    border-radius: 20px;
    box-shadow: 0 2px 30px rgb(0 0 0 / 10%);
    max-width: 500px;
    padding: 40px;
    width: 100%
}


/* card1 */
.card.card1 {
    background: #212229;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 30px rgb(0 0 0 / 8%);
    border: 0;
    overflow: hidden;
    height: 100%;
}

.card.card1 .card-header {
    background: #212229;
    border-color: rgb(255 255 255 / 10%);
    padding: 20px 24px;
}

.card.card1 .card-body {
    padding: 24px 24px;
}



/* .table.table1 */

.table.table1 thead th {
    background: #000;
    color: #fff;
    font-size: .875em;
    font-weight: 400;
    padding: 12px;
    white-space: nowrap;
    border-color: rgb(255 255 255 / 20%);
}

.table.table1 tbody td {
    padding: 12px;
    font-size: .875em;
    color: #fff;
    vertical-align: middle;
    border-color: rgb(255 255 255 / 20%);
    background: #212229;
}
.table.table1 tbody td:first-child,
.table.table1 thead th:first-child {
    padding-left: 24px;
}
.table.table1 tbody td:last-child,
.table.table1 thead th:last-child {
    padding-right: 24px;
}

.table.table1 tbody td .btn {
    box-shadow: none;
    outline: none;
}
/* .table.table1 tbody td .btn:hover{
    color: #f0b961;
} */
.table.table1 tbody td .img1 {
    width: 80px;
    min-width: 80px;
    height: auto;
    border-radius: 3px;
}

.table.table1.border {
    border: 1px solid rgb(255 255 255 / 20%) !important;
}


.badge.win {
    color: #45ee92;
    background: rgba(69, 238, 146, 0.1);
}
.badge.loss {
    color: #e90f3d;
    background: rgb(233 15 61 / 10%);
}

.modal.modal1 .modal-content {
    background: #000;
    color: #fff;
    border: 0;
}

.modal.modal1 .modal-content button.btn-close {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
    box-shadow: none !important;
    height: 2px;
    opacity: 1;
    outline: none !important;
    width: 2px;
}

/* nav-tabs1 */
.nav.nav-tabs1 {
    background: #151619;
    border-radius: 5px;
}
.nav.nav-tabs1 .nav-item {
    width: 100%;
}

.nav.nav-tabs1 .nav-link {
    box-shadow: none !important;
    outline: none !important;
    border: 0;
    color: #fff;
    background: transparent;
    border-radius: 5px;
    padding: 12px 20px;
    width: 100%;
}

.nav.nav-tabs1 .nav-link.active {
    background: #f0b961;
    color: #000;
}

.bg1 {
    background-color: transparent;
    border: 2px solid rgb(255 255 255 / 12%);
    color: #fff;
    padding: 12px 20px;
    border-radius: 10px;
}

/* .pagination1 */
.pagination1 .page-item .page-link {
    background: #212229;
    color: #fff;
    border: 0;
    box-shadow: none !important;
    outline: none !important;
}

.pagination1 .page-item.active .page-link {
    background: #f0b961;
    color: #000;
}

.pagination1 .page-item.disabled .page-link {
    color: #989898;
}

.pending {
    background: #be9519;
}
.approve {
    background: #0e8f0e;
}
.yes {
    background: #be9519 !important;
}
.no {
    background: #dc3545 !important;
}


.table.table1 .dropdown.dropdown1 button.btn{
    color: #fff;
}
.table.table1 .dropdown.dropdown1 button.btn::after {
    height: 6px;
    width: 6px;
}
.table.table1 .dropdown.dropdown1 .dropdown-menu {
    background: #000;
    position: fixed !important;
}
.dropdown.dropdown1 .btn.pending {
    background-color: #be9519 !important;
}
.dropdown.dropdown1 .btn.approve {
    background-color: #0e8f0e !important;
}

.dropdown.dropdown1 .btn.yes {
    background: #be9519 !important;
}
.dropdown.dropdown1 .btn.no {
    background: #dc3545 !important;
}

/* daterange picker */
.react-daterange-picker__wrapper {
    border: 0 !important;
}

.react-daterange-picker__inputGroup__input {
    border: 0;
    box-shadow: none;
    outline: none;
}

.react-daterange-picker__button svg {
    stroke: #fff !important;
}

.react-calendar__month-view__weekdays__weekday {
    color: #000;
}

/* date picker */
.react-date-picker__wrapper {
    border: 0 !important;
}

.react-date-picker__inputGroup__input {
    border: 0;
    box-shadow: none;
    outline: none;
}

.react-date-picker__button svg {
    stroke: #fff !important;
}

.react-calendar__month-view__weekdays__weekday {
    color: #000;
}

.react-calendar {
    border-radius: 10px;
}

/* time picker */
.react-time-picker__wrapper {
    border: 0 !important;
}

.react-time-picker__inputGroup__input {
    border: 0;
    box-shadow: none;
    outline: none;
}

.react-time-picker__button svg {
    stroke: #fff !important;
}
.react-time-picker__inputGroup__input option {
    color: #000;
}

.react-time-picker__clock {
    border-radius: 10px;
}









/* scrollbar */
.scrollbar
{
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #221d3e;
}

.scrollbar::-webkit-scrollbar
{
  width: 5px;
  height: 5px;
  background-color: #221d3e;
}

.scrollbar::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #f0b961;
}

/* footer-section  */
.footer-section {
    background: #151619;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border-top: 1px solid rgb(255 255 255 / 12%);
}