

@media (min-width: 992px) {
    .offcanvas.offcanvas1 {
        visibility: visible;
        transform: none;
    }
    .main-content {
        margin-left: 280px;
    }
}

@media (max-width: 991px) {
    .header-top {
        background: #000;
    }
}

@media (max-width: 767px) {
    .padding {
        padding: 20px 3px;
    }
    .header-top {
        padding: 20px 3px;
    }
    .login-section .logo1 {
        width: 110px;
    }

    .logo1 {
        width: 80px;
    }
    .login-box {
        padding: 24px;
    }
    .card.card1 .card-header {
        padding: 15px;
    }
    .card.card1 .card-body {
        padding: 15px;
    }
    .table.table1 tbody td:first-child,
    .table.table1 thead th:first-child {
        padding-left: 15px;
    }
    .table.table1 tbody td:last-child,
    .table.table1 thead th:last-child {
        padding-right: 15px;
    }
}

@media (max-width: 575px) {
    .nav.nav-tabs1 .nav-link {
        font-size: .875rem;
        padding: 10px 15px;
    }
}